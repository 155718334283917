import { GlobalEventsService } from '../services/global-events.service';
import { PwaService } from '../services/pwa.service';
import { createInitializerQueueList } from './initializer-manager';
import { StagDetectorService } from '../services/stag-detector.service';
import { SeoService } from '../services/seo.service';
import { EnvironmentProviders, inject, Injector, provideAppInitializer } from '@angular/core';
import { DecoratorService } from '../helpers/decorator-service.service';
import { InstallAppService } from '../services/install-app.service';
import { UserPaymentModeService } from '../services/user/user-payment-mode.service';
import { GoogleTagManagerService } from '../services/google-tag-manager.service';
import { UserService } from '../services/user/user.service';

/**
 * The `initApp` function initializes the DecoratorService with the provided Injector.
 */
export function initApp(injector: Injector) {
  DecoratorService.injector = injector;
}

export const provideInitializer = () => {
  return [
    provideAppInitializer(() => {
      const injector = inject(Injector);
      initApp(injector);
      return Promise.resolve();
    }),
    ...parallelInitializer,
  ];
};

const parallelInitializer: EnvironmentProviders[] = createInitializerQueueList([
  {
    token: StagDetectorService,
    initializerFn: (service: StagDetectorService) => service.detectStag(),
  },
  {
    token: GlobalEventsService,
    initializerFn: (service: GlobalEventsService) => service.initGlobalEvent(),
  },
  {
    token: GoogleTagManagerService,
    initializerFn: (service: GoogleTagManagerService) => service.addGtmToDom(),
  },
  {
    token: PwaService,
    initializerFn: (service: PwaService) => service.registerServiceWorker(),
  },
  {
    token: SeoService,
    initializerFn: (service: SeoService) => service.updateGlobalHeadLink(),
  },
  {
    token: InstallAppService,
    initializerFn: (service: InstallAppService) => service.resolveShowVariables(),
  },
  {
    token: UserPaymentModeService,
    initializerFn: (service: UserPaymentModeService) => service.resolveModeFromUrl(),
  },
  {
    token: UserService,
    initializerFn: (service: UserService) => service.updateUserLang(),
  },
]);
