import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ArrayService {

  constructor() {
  }

  /**
   * Split array to chunks
   *
   * Example:
   *  toChunks([1, 2, 3, 4, 5, 6, 7], 3)
   *
   * Output:
   *  [[1, 2, 3], [4, 5, 6], [7]]
   *
   * @param original
   * @param chunkSize
   */
  toChunks(original: Array<any>, chunkSize: number) {
    const result = [];

    for (let i = 0; i < original.length; i += chunkSize) {
      result.push(original.slice(i, i + chunkSize));
    }

    return result;
  }

  /**
   * Cycle array items
   *
   * @param {any[]} originalArray
   * @param {number} count
   * @returns {any[]}
   * @private
   */
  cycle(originalArray: any[], count: number) {

    const newArray: any[] = [];

    if (!originalArray || !originalArray.length) {
      return newArray;
    }

    while (newArray.length < count) {
      for (const arr of originalArray) {
        if (newArray.length < count) {
          newArray.push(arr);
        }
      }
    }

    return newArray;
  }

  /**
   * Returns array from object values
   *
   * Example:
   *  fromObjectValues({
   *    error: {
   *      amount: 'not enough funds'
   *    }
   *  })
   *
   * Output:
   *  ['not enough funds']
   *
   * @param object
   */
  fromObjectValues(object: object): any[] {
    const list: any[] = [];

    Object.values(object).forEach(
      value => typeof value === 'object'
        ? list.push(...this.fromObjectValues(value))
        : list.push(value)
    );

    return list;
  }

  /**
   * Returns array of url segments from string
   * @param route
   * @private
   */
  routeToArray(route: string): Array<string> {
    const url = route.split('?')[0];
    return (url.split('/')).splice(1);
  }

  /**
   * Check if array has min length of game and clone it
   * @param gameList
   * @param slideCount
   * @private
   */
  checkAndCloneArray(gameList: any[], slideCount: number) {
    let cloneArr = [];
    const arrayToChunks = this.toChunks(gameList, slideCount);
    if (gameList.length % slideCount === 0) {
      cloneArr = gameList;
    } else {
      cloneArr = this.cycle(gameList, gameList.length + slideCount - arrayToChunks[arrayToChunks.length - 1].length);
    }
    return cloneArr;
  }

  /**
   * Shuffle array
   * @param array
   */
  shuffle(array: any[]) {
    let currentIndex = array.length;
    let temporaryValue;
    let randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  /**
   * Move element position in array
   * var arr = [ 'a', 'b', 'c', 'd', 'e'];
   * arr.move(3,1);//["a", "d", "b", "c", "e"]
   * @param array
   * @param from
   * @param to
   * @returns array
   */
  move(array: any[], from: number, to: number) {
    array.splice(to, 0, array.splice(from, 1)[0]);
    return array;
  }

  /**
   * Returns array of unique object values from array of objects by key
   *
   * Example:
   *  uniqueBy('id', [
   *    {id: 21, amount: 1},
   *    {id: 21, amount: 2},
   *    {id: 4, amount: 5},
   *  ])
   *
   * Output:
   *  [
   *    {id: 21, amount: 1},
   *    {id: 4, amount: 5},
   *  ]
   *
   * @param key
   */
  public uniqueBy(prop: string, arr: any[]) {
    return [...new Map(arr.map(item => [item[prop], item])).values()];
  }

  /**
   * Sort array of objects based on another array
   */
  public mapOrder(array, order, key) {
    array.sort(function (a, b) {
      const A = a[key], B = b[key];
      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });
    return array;
  }

  /**
   * Generic function can be with your custom condition to compare two arrays and get their difference array
   * @param oldArray
   * @param newArray
   * @param customCondition
   * @private
   */
  public arrayDifference(oldArray: any[], newArray: any[], customCondition = null): any {
    return [...oldArray.filter(o1 => !newArray.some(o2 => customCondition ? customCondition(o1, o2) : o1 === o2)),
      ...newArray.filter(o1 => !oldArray.some(o2 => customCondition ? customCondition(o1, o2) : o1 === o2))];
  }

  /**
   * Example:
   * [26, 27, 26, 26, 28, 28, 29, 29, 30] => [26, 27, 28, 29, 30]
   * @param array
   * @returns
   */
  public unique(array: any[]): any[] {
    const arr = [];
    if (array?.length) {
      for (let i = 0; i < array.length; i++) {
        if (!arr.includes(array[i])) {
          arr.push(array[i]);
        }
      }
    }
    return arr;
  }

}
