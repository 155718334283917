
export enum ApolloClientErrorCodes {
  NOT_FOUND = 'NOT_FOUND',
  GAME_NOT_FOUND_BY_UUID = 'GAME_NOT_FOUND_BY_UUID',
  CONSTRAINT_VIOLATION = 'CONSTRAINT_VIOLATION',
  PLAYER_ALREADY_EXISTS = 'PLAYER_ALREADY_EXISTS',
  UNAUTHORIZED = 'UNAUTHORIZED',
};

export type ApolloClientErrorCodesType = keyof typeof ApolloClientErrorCodes;
