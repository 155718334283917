import { Injectable, inject } from '@angular/core';
import { PlatformService } from './platform.service';
import { UserService } from './user/user.service';
import { GlobalEventsService } from './global-events.service';
import { CookieService } from 'ngx-unificator/services';
import { LanguageService } from './language.service';
import { distinctUntilChanged, filter, first, map, tap } from 'rxjs/operators';
import { CmsApiService } from './api/cms-api.service';
import { CmsContentMapperService } from './cms-content-mapper.service';
import { HostRequestsInterceptor } from '../interceptors/host-requests.interceptor';
import { StaticContentService } from './static-content.service';

declare global {
  interface Window {
    OneSignal: any;
    OneSignalDeferred: any;
  }
}

declare var OneSignal;

@Injectable({
  providedIn: 'root',
})
export class OneSignalService {
  private _platform = inject(PlatformService);
  private _globalEvents = inject(GlobalEventsService);
  private _cookie = inject(CookieService);
  private _language = inject(LanguageService);
  private _user = inject(UserService);
  private _api = inject(CmsApiService);
  private _mapper = inject(CmsContentMapperService);
  private _hostInterceptor = inject(HostRequestsInterceptor);
  private _static = inject(StaticContentService);

  private _appId = 'f4032859-83cf-4968-bdd0-28482ebd5d21';

  constructor() {
    if (this._platform.isBrowser) {
      this.handleId();
    }
  }

  /**
   * Get one signal id from CMS
   */
  handleId() {
    if (this._platform.isBrowser) {
      this._static
        .item({ slug: 'one-signal-ids' })
        .pipe(
          filter(item => !!item?.length),
          first(),
          map(item => item[0]?.Grouplist),
          map(mirrors => {
            const hostname = this._hostInterceptor.getDomainName().split('.')[1];
            return mirrors[hostname]?.id?.trim() || this._appId;
          }),
          tap((appId: string) => this.onInit(appId)),
        )
        .subscribe();
    }
  }

  /**
   * Init one signal and apply id from CMS
   */
  onInit(id: string): void {
    window.OneSignalDeferred = window.OneSignalDeferred || [];
    window.OneSignalDeferred.push(async () => {
      await OneSignal.init({
        appId: id,
        notifyButton: {
          enable: true,
        },
        serviceWorkerParam: { scope: '/push/onesignal/' },
        serviceWorkerPath: 'push/onesignal/OneSignalSDKWorker.js',
      }).then(() => {
        this._autoUpdateUser();
        this._setExternalId();
      });
    });
  }

  // This function will work only if Onesignal init. So it will don`t work for localhost or stage
  // And will not send test users data. To make it work change in Onesignal admin panel in app test url to localhost
  private _autoUpdateUser() {
    this._globalEvents.routerNavigationEnd$
      .pipe(
        distinctUntilChanged(),
        tap(data => {
          OneSignal.push(() => {
            this._setUserTags(this._createUserTagsObj());
          });
        }),
      )
      .subscribe();
  }

  private _setUserTags(data) {
    OneSignal?.User?.addTags(data);
  }

  private _createUserTagsObj() {
    if (this._user.auth) {
      return {
        isUserRegistered: this._cookie.check('registered')?.toString(),
        // isUserHadDeposit: (this._groups.isExistGroup('ID76') || this._user.accountWalletList.some(e => e.amount > 0))?.toString(),
        userLanguage: this._language.current,
        isHaveBalance: this._user.accountWalletList.some(e => e.amount > 0)?.toString(),
      };
    } else {
      return {
        isUserRegistered: this._cookie.check('registered')?.toString(),
        userLanguage: this._language.current,
      };
    }
  }

  private _setExternalId() {
    OneSignal.push(async () => {
      if (OneSignal?.User?.PushSubscription?.id) {
        OneSignal.login(OneSignal.User.PushSubscription.id);
      }
    });
  }
}
