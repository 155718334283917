import { Injectable, inject } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { CookieService } from 'ngx-unificator/services';
import { PlatformService } from './platform.service';
import { Location } from '@angular/common';
import { ReplaySubject } from 'rxjs';
import { LocalstorageService } from './localstorage.service';
import { CmsApiService } from './api/cms-api.service';
import { NATIVE_APP_COOKIE } from './install-app.service';

export const PTAG_STRING = 'ptag';
export const PARTNER_ID_STRING = 'id_id';
export const PARTNER_HEADER_ID_STRING = 'id-id';
export const LOCAL_STORAGE_STAG_URL_KEY = 'stag_url';

enum AffiliateUrlParams {
  affiliate_id = 'affiliate_id',
  cxd = 'cxd',
  campaign_id = 'campaign_id',
  promo_c = 'promo_c',
  clickid = 'clickid',
  fbclid = 'fbclid',
  GCLID = 'GCLID',
  creative = 'creative',
  sub_aff_id = 'sub_aff_id',
  channel = 'channel',
  customer_id = 'customer_id',
  utm_source = 'utm_source',
  utm_medium = 'utm_medium',
  utm_campaign = 'utm_campaign',
  utm_term = 'utm_term',
  utm_content = 'utm_content',
  source = 'source',
  cid = 'cid',
  ap = 'ap',
  aid = 'aid',
  offer_id = 'offer_id',
  affiliate_id_hash = 'affiliate_id_hash',
  affiliate_name = 'affiliate_name',
  sub_id1 = 'sub_id1',
  sub_id2 = 'sub_id2',
  sub_id3 = 'sub_id3',
  sub_id4 = 'sub_id4',
  sub_id5 = 'sub_id5',
  sub_id1_hash = 'sub_id1_hash',
  sub_id2_hash = 'sub_id2_hash',
  sub_id3_hash = 'sub_id3_hash',
  sub_id4_hash = 'sub_id4_hash',
  sub_id5_hash = 'sub_id5_hash',
  aff_param1 = 'aff_param1',
  aff_param2 = 'aff_param2',
  aff_param3 = 'aff_param3',
  aff_param4 = 'aff_param4',
  aff_param5 = 'aff_param5',
  link_id = 'link_id',
  creative_id = 'creative_id',
  offer_name = 'offer_name',
  device_type = 'device_type',
  device_brand = 'device_brand',
  device_os = 'device_os',
  device_model = 'device_model',
  browser = 'browser',
  user_agent = 'user_agent',
  connection_type = 'connection_type',
  mobile_operator = 'mobile_operator',
  geo_country_code = 'geo_country_code',
  geo_country = 'geo_country',
  geo_region = 'geo_region',
  geo_city = 'geo_city',
  ip = 'ip',
  idfa = 'idfa',
  gaid = 'gaid',
  custom1 = 'custom1',
  custom2 = 'custom2',
  custom3 = 'custom3',
  custom4 = 'custom4',
  custom5 = 'custom5',
  custom6 = 'custom6',
  custom7 = 'custom7',
  custom8 = 'custom8',
  custom9 = 'custom9',
  custom10 = 'custom10'
}

@Injectable({
  providedIn: 'root',
})
export class StagDetectorService {
  private _cookie = inject(CookieService);
  private _router = inject(Router);
  private _serializer = inject(UrlSerializer);
  private _platform = inject(PlatformService);
  private _location = inject(Location);
  private _localStorage = inject(LocalstorageService);
  private _api = inject(CmsApiService);

  private _affiliateUrlParams: any[] = [];
  private _registerAffiliateParams: any[] = [];

  public onDetectStag$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public get affiliateUrlParams(): any[] {
    return this._affiliateUrlParams;
  }

  public get registerAffiliateParams(): any[] {
    return this._registerAffiliateParams;
  }

  /**
   * The function `detectStag` in TypeScript detects and processes query parameters and cookies related
   * to affiliate tracking on a browser platform.
   * @returns The `detectStag` function returns either nothing (undefined) or the result of
   * `this._router.navigateByUrl(tree)` depending on the conditions met within the function.
   */
  public detectStag() {
    if (!this._platform.isBrowser) {
      return;
    }

    const params = this._serializer.parse(window.location.search).queryParams;
    const domain = window.location.hostname.split('.').slice(-2).join('.');
    const isNeedClear = Boolean(Object.keys(params || {})?.length);

    setTimeout(() => {
      if (params.appver || params.appID) {
        this._cookie.set(NATIVE_APP_COOKIE, 'true', 999, '/', domain);
        this._localStorage.set(NATIVE_APP_COOKIE, 'true');
      }
    }, 100);

    if (params[AffiliateUrlParams.cxd]) {
      params[AffiliateUrlParams.affiliate_id] = params[AffiliateUrlParams.cxd]?.split('_')[0];
    }

    if (params[AffiliateUrlParams.aid]) {
      params[AffiliateUrlParams.affiliate_id] = params[AffiliateUrlParams.aid];
    }

    Object.keys(params || {}).forEach(p => {
      this._paramReducer(p, params, domain);
    });

    if (!params[AffiliateUrlParams.affiliate_id] || !params[AffiliateUrlParams.aid]) {
      if (this._cookie.check(PTAG_STRING)) {
        this._localStorage.set(PARTNER_ID_STRING, this._cookie.get(PTAG_STRING));
        this._localStorage.set(PTAG_STRING, this._cookie.get(PTAG_STRING));
        this._cookie.set(PARTNER_ID_STRING, this._cookie.get(PTAG_STRING), 99, '/', domain);
        this._api.customNoLangHeadersList.push({ key: PARTNER_HEADER_ID_STRING, val: this._cookie.get(PARTNER_ID_STRING) });
        this._api.customHeadersList.push({ key: PARTNER_HEADER_ID_STRING, val: this._cookie.get(PARTNER_ID_STRING) });
      }
    }

    if (isNeedClear) {
      setTimeout(() => {
        const tree = this._router.parseUrl(this._location.normalize(window.location.pathname));
        tree.queryParams = params;
        this._router.navigateByUrl(tree);
      }, 100);
    }

    if (!isNeedClear) {
      Object.keys(AffiliateUrlParams).forEach(param => {
        const currentParam = this._localStorage.get(param);
        if (currentParam) {
          this._affiliateUrlParams.push({
            [param]: currentParam,
          });
          this._registerAffiliateParams.push({
            name: param,
            value: currentParam,
          });
        }
      });
    }
  }

  /**
   * The function `_paramReducer` processes parameters based on a specific case, setting values in local
   * storage, cookies, and custom headers accordingly.
   * @param param - The `param` in the `_paramReducer` function is a parameter that determines the action
   * to be taken based on its value. It is used in a switch statement to handle different cases of
   * parameters.
   * @param paramList - It seems like you were about to provide the `paramList` object but it got cut
   * off. Could you please provide the `paramList` object so that I can assist you further with the
   * `_paramReducer` function?
   * @param domain - The `domain` parameter in the `_paramReducer` function is used as a variable
   * representing the domain where the cookies will be set. It is passed as an argument to the function
   * and is used in setting cookies with specific domain paths.
   */
  private _paramReducer(param, paramList, domain) {
    if (!Object.keys(AffiliateUrlParams).includes(param)) {
      return;
    }

    this._affiliateUrlParams.push({
      [param]: paramList[param],
    });
    this._registerAffiliateParams.push({
      name: param,
      value: paramList[param],
    });
    switch (param) {
      case AffiliateUrlParams.affiliate_id:
        this._resolveAffiliateId(param, paramList, domain, 'affiliate_id');
        break;

      case AffiliateUrlParams.aid:
        this._resolveAffiliateId(param, paramList, domain, 'aid');
        break;

      default:
        this._localStorage.set(param, paramList[AffiliateUrlParams[param]]);
        delete paramList[AffiliateUrlParams[param]];
        break;
    }
  }

  /**
   * Resolves the affiliate ID and performs various actions based on the affiliate parameters.
   * This function sets values in local storage, cookies, and custom headers based on the affiliate parameters.
   * @param param - The parameter that determines the action to be taken.
   * @param paramList - The list of affiliate parameters.
   * @param domain - The domain where the cookies will be set.
   * @param affiliate_id - The affiliate ID, used as a fallback if the `param` is not recognized.
   */
  private _resolveAffiliateId(param, paramList, domain, affiliate_id: string = '') {
    this._localStorage.set(LOCAL_STORAGE_STAG_URL_KEY, window.location.pathname);
    this._localStorage.set('stag', paramList[AffiliateUrlParams[affiliate_id]]);
    this._localStorage.set(param, paramList[AffiliateUrlParams[param]]);
    if (this._cookie.check(PTAG_STRING)) {
      this._localStorage.set(PARTNER_ID_STRING, this._cookie.get(PTAG_STRING));
      this._localStorage.set(PTAG_STRING, this._cookie.get(PTAG_STRING));
      this._cookie.set(PARTNER_ID_STRING, this._cookie.get(PTAG_STRING), 99, '/', domain);
      this._api.customNoLangHeadersList.push({ key: 'ID-ID', val: paramList[AffiliateUrlParams[affiliate_id]] });
      this._api.customHeadersList.push({ key: 'ID-ID', val: paramList[AffiliateUrlParams[affiliate_id]] });
    } else {
      this._localStorage.set(PARTNER_ID_STRING, paramList[AffiliateUrlParams[affiliate_id]]);
      this._localStorage.set(PTAG_STRING, paramList[AffiliateUrlParams[affiliate_id]]);
      this._cookie.set(PARTNER_ID_STRING, paramList[AffiliateUrlParams[affiliate_id]], 99, '/', domain);
      this._cookie.set(PTAG_STRING, paramList[AffiliateUrlParams[affiliate_id]], 99, '/', domain);
      this._api.customNoLangHeadersList.push({ key: 'ID-ID', val: paramList[AffiliateUrlParams[affiliate_id]] });
      this._api.customHeadersList.push({ key: 'ID-ID', val: paramList[AffiliateUrlParams[affiliate_id]] });
    }
    this.onDetectStag$.next({ stag: paramList[AffiliateUrlParams[affiliate_id]] });
    delete paramList[AffiliateUrlParams[affiliate_id]];
  }
}
