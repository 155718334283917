import { SsWebSocketsPrivateChanel, SsWebSocketsPublicChanel } from './ss-web-sockets.types';
import { SsWebSocket } from './ss-web-socket';
import { filter, first, map, tap } from 'rxjs/operators';


export class SsSocketChanel {

  /**
   * Messages from chanel
   */
  public message = this._connection.onMessage.pipe(
    filter(message => message.channel.includes(this._chanel)),
    map(message => ({...message, data: {...message.data, uid: message.uid}})),
    map(message => message.data),
  );

  constructor(
    private _chanel: SsWebSocketsPublicChanel | SsWebSocketsPrivateChanel,
    private _connection: SsWebSocket
  ) {
    _connection.connected$.pipe(
      first(),
      tap(() => {
        _connection.subscribe(_chanel);
      })
    ).subscribe();
  }
}
