import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { first, mergeMap, startWith, switchMap } from 'rxjs/operators';
import { PlatformService } from '../services/platform.service';
import { CacheControlService } from '../services/cache-control.service';

export interface CacheControl {
  cacheKey: string;
  version: number;
}

export const initialRequests = ['/environment', '/cache/cf'];

@Injectable({
  providedIn: 'root',
})
export class CacheControlInterceptor implements HttpInterceptor {
  private _cache = inject(CacheControlService);
  private _platform = inject(PlatformService);


  /**
   * Can use from Environment cache
   * @private
   */

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this._platform.isBrowser || !this._isRequestToCms(req) || initialRequests.some(r => req.url.includes(r))) {
      return next.handle(req);
    }
    return this._cache.userAuth$.pipe(
      startWith(false),
      first(),
      switchMap(() => this._cache.cache$ || of({ data: { cacheControl: [] } })),
      mergeMap(cache => {
        const version = this._getCacheVersion(cache.data.cacheControl, req.url);

        if (version) {
          req = req.clone({
            setParams: { bcv: String(version) },
          });
        }

        return next.handle(req);
      }),
    );
  }

  /**
   * Check request to CMS
   * @param req
   * @private
   */
  private _isRequestToCms(req: HttpRequest<any>): boolean {
    return req.url.startsWith(environment.cms_host) && req.method === 'GET';
  }

  /**
   * Get cache version
   * @private
   */
  private _getCacheVersion(cache: CacheControl[] = [], requestUrl): string {
    const cacheItem = cache.find(c => requestUrl.includes(c.cacheKey));
    return cacheItem ? String(cacheItem.version) : null;
  }
}
